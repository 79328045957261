<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Banco">
        <BasicFormToolbar
          @new="openNew" @save="save" @list="openTable"
          :actions="['new','save','list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <Fieldset :toggleable="true" legend="Banco">
                <div class="p-fluid formgrid grid">
                  <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="entity.name" 
                  :valid="validate.validations.name"/>
                  <FormInputText wrapperClass="field col-12 xl:col-2" label="Nombre Corto" v-model="entity.key_name" 
                  :valid="validate.validations.key_name"/>
                  <FormDropdownComplex :valid="validate.validations.code_SAT" :labelInOption="'c_Banco - Descripcion'" 
                  :labelInValue="'c_Banco - Descripcion'" :wrapperClass="'field col-2'" label="Codigo SAT" 
                  v-model="entity.code_SAT" :options="c_Banco" optionLabel="c_Banco" optionValue="c_Banco" />
                  <FormInputText wrapperClass="field col-12 xl:col-4" label="RFC" v-model="entity.rfc" 
                  :valid="validate.validations.rfc"/>
                </div>
              </Fieldset>
              <br>
              <Fieldset :toggleable="true" legend="Registros">
        <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
              </Fieldset>
              <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { Bank } from "../../../models/cxp/Bank";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import {satCatalogo,  ErrorToast , HeaderGrid, fillObject, Rule, validate, Toast} from '../../../utilities/General';
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import Session from "../../../mixins/sessionMixin";

export default {
  mixins: [Session],
  data() {
    return {
      open: false,
      entity: null,
      newDialog: false,
      uploadURL: null,
      c_Banco: [],
      entities: [],
      loading: false,
      deleteDialog: false,
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null,
          code_SAT: null
        },
      },
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "code_SAT" }),
        new Rule({ name: "rfc" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Codigo SAT", "code_SAT"),
        new HeaderGrid("rfc", "rfc"),
      ],
    };
  },
  components: {BasicFormToolbar, FormInputText, FormDropdownComplex, BasicDatatable , DeleteDialog},
  created() {
    this.entity = new Bank(this.session);
    this.uploadURL =
      this.$config.api_route +
      "cxp/Bank/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
  },
  async mounted() {
    await this.refresh();
    //Aqui cargamos los CATALOGOS ESTATICOS
    (this.c_Banco = await satCatalogo(28)), (this.loading = false);
  },
  methods: {
    async openTable(){
      this.entities = await this.entity.all();
      this.open = true;
    },
    deleted() {
        this.deleteEntity();
    },
    openNew() {
      this.entity = new Bank(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }

        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {

          console.log("Guardado: " + JSON.stringify(this.entity))
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.entities = null;
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new Bank(this.session);
        if (this.open == true){
          this.entities = await this.entity.all();
        }
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new Bank(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    initFilters() {
      
    },
    uploadError() {
    },
    async refresh() {
      this.loading = true;
      try {
        let hoal;
        console.log(hoal)
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
